import { Button, FormControlLabel, Grid, Paper, Switch } from "@mui/material";
import AppDateRangeSelector from "../../../app/components/app-date-range-selector";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LoadingIndicator from "../../../app/components/loading-indicator";
import { Done } from "@mui/icons-material";
import { changeDateReportAct, changeFilterExcludeDatesAct, changeFilterUserIdAct, changeRaffleReportAct, changeStatusReportAct, reportGetSellThunk } from "../reports.slice";
import AppSelector from "../../../app/components/app-selector";
import { useEffect } from "react";
import { getRafflesThunk } from "../../raffles-list/rafles-list.slice";
import { dateToInputDate } from "../../../utils/date.utils";
import moment from "moment";
import { fetchUsersThunk } from "../../users-list/user-list.slice";

export default function ReportsFilter() { 
  const dispatch = useAppDispatch()
  const {filter, loading} = useAppSelector((state) => state.reports)
  const { currentUser } = useAppSelector((state) => state.login)
  const {users} = useAppSelector((state) => state.users)
  const {raffles} = useAppSelector((state) => state.raflesList)

  const getSells = () => {
    const {dateFrom, dateTo, raffleId, status} = filter
    if(dateFrom && dateTo && raffleId && status !== undefined) dispatch(reportGetSellThunk(filter))
  }


  useEffect(() => {
    dispatch(getRafflesThunk())
    dispatch(fetchUsersThunk())
  }, [])

  const filterUsers = () => {
    return currentUser!.level !== 0 ? users.filter((u) => u._id === currentUser!._id) : users
  } 

  const filterRaffles = () => {
    return currentUser!.level === 0 ? raffles : raffles.filter(f => (f.status === 0 || f.status === 1));
  }
  return (
    <> 
      {currentUser !== undefined && <>
        <LoadingIndicator open={loading}/>
        <Paper sx={{padding: 1, marginBottom: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <AppSelector label="Sorteo" name="raffle" options={filterRaffles()} onChange={(e) => dispatch(changeRaffleReportAct(e.val))}/>
            </Grid>
            <Grid item xs={12} md={3}>
            {/* //pending:0, //payed:1, //Cancelled:2  //Refun: 3 //All -1 */}
              <AppSelector label="Status" name="status"
                value={filter.status}
                onChange={(d) => dispatch(changeStatusReportAct(Number(d.val)))}
                options={[
                  {name: "Todos", _id: -1},
                  {name: "Pendiente", _id: 0},
                  {name: "Pagago", _id: 1},
                  {name: "Cancelado", _id: 2},
                ]}
              />
            </Grid>
            <Grid item xs={12} md={3}>
            {/* //pending:0, //payed:1, //Cancelled:2  //Refun: 3 //All -1 */}
              <AppSelector label="Vendedor o Referido" name="status"
                value={filter.userId}
                onChange={(d) => dispatch(changeFilterUserIdAct(d.val))}
                options={filterUsers()}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel control={<Switch checked={filter.excludeDates}/>} onChange={(e, c) => dispatch(changeFilterExcludeDatesAct(c))} label="Excluir fechas"/>
            </Grid>
            <Grid item xs={10} md={4}>
              <AppDateRangeSelector 
                id="reportsDateRange" 
                dateEnd={moment(filter.dateTo).toDate()}
                dateStart={moment(filter.dateFrom).toDate()}
                onChange={(dates) => dispatch(changeDateReportAct({dateFrom: dateToInputDate(dates.dateStart.toISOString()), dateTo: dateToInputDate(dates.dateEnd.toISOString())}))}  
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" size="small" onClick={getSells}> <Done/> </Button>
            </Grid>
          </Grid>
        </Paper>
      </>}
    </>
  )
}